import Rails from '@rails/ujs';
Rails.start();

require('turbolinks').start()
//require("@rails/activestorage").start()
//import "@babel/polyfill"
import(
  /* webpackChunkName: "application-libs" */
  /* webpackMode: "lazy" */
  'libraries'
).then(module => {
  const Libraries = module.default
  //l = new Libraries()
  //l.install()
})
//import Libraries from 'libraries'
